import { Button, Col, Form, Row } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem, FormSelect } from "@/ui/_common_/form-helpers";

import { AddDocumentFormState } from "./add-form.state";

interface Props {
  state: AddDocumentFormState;
}

export const AddDocumentFormView = observer<Props>(
  ({ state: { form, mutation, handleSubmit } }) => {
    return (
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ErrorsFormatter queries={[mutation]} />
        <FormItem form={form} path="type" label={"Type"}>
          <FormSelect
            form={form}
            path="type"
            options={[
              { label: "General", value: "general" },
              { label: "Contacts", value: "contacts" },
              { label: "WOF How-to-work", value: "wof_howtowork" },
              { label: "WOF Rules", value: "wof_rules" },
            ]}
          />
        </FormItem>
        <FormItem form={form} path="title" label="Title">
          <FormInput form={form} path="title" placeholder="Enter title" />
        </FormItem>
        <FormItem form={form} path="slug" label="Slug">
          <FormInput form={form} path="slug" placeholder="Enter slug" />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  },
);
