import yup from "@/yup";

export function useEditDocumentFormSchema() {
  return yup.object({
    title: yup.string().min(2).default("").defined(),
    slug: yup.string().min(1).default("").defined(),
    isActive: yup.boolean().defined(),
    requirePlayerAccept: yup.boolean().defined(),
  });
}

export type EditDocumentFormSchema = yup.InferType<
  ReturnType<typeof useEditDocumentFormSchema>
>;
