import yup from "@/yup";

export function useEditTranslationFormSchema() {
  return yup.object({
    title: yup.string().default("").defined(),
    body: yup.string().default("").defined(),
    version: yup.string().default("").defined(),
  });
}

export type EditTranslationFormSchema = yup.InferType<
  ReturnType<typeof useEditTranslationFormSchema>
>;
