import { observer } from "mobx-react-lite";

import { CloseAddDocumentModal, OpenAddDocumentModal } from "@/events";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { AddDocumentForm } from "./add-form";

export const AddDocumentModal = observer(() => {
  return (
    <EventBasedModal
      openEvent={OpenAddDocumentModal}
      closeEvent={CloseAddDocumentModal}
      title={"Create Document"}
    >
      {({ entity }) => <AddDocumentForm entity={entity} />}
    </EventBasedModal>
  );
});
