import { observer } from "mobx-react-lite";

import { useEditDocumentFormState } from "./edit-form.state";
import { EditDocumentFormView } from "./edit-form.view";

export type EditDocumentFormProps = { id: number };

export const EditDocumentForm = observer<EditDocumentFormProps>((props) => {
  const state = useEditDocumentFormState(props);
  return <EditDocumentFormView state={state} />;
});
