import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Row,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import { observer } from "mobx-react-lite";

import { TDocumentFilterItem } from "@/api";
import { OpenAddDocumentModal, OpenEditDocumentModal } from "@/events";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { AddDocumentModal } from "./add-modal";
import { DeleteAction } from "./delete-action";
import { EditDocumentModal } from "./edit-modal";
import { DocumentsPageState } from "./page.state";

const DocumentsPage = observer(() => {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new DocumentsPageState(globalStore), {});
  const { permissionsStore, eventBusService } = globalStore;
  return (
    <Page title="Documents">
      <AddDocumentModal />
      <EditDocumentModal />
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={"Select client"}
              />
            </Col>
          )}
          {(state.clientSelectorStore.selectedId ?? 0) > 0 && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={"Select an agent"}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={"Select subagent"}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={"Select shop"}
                  />
                </Col>
              )}
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {("agentId" in (state.entity ?? {}) ||
            "hallId" in (state.entity ?? {})) && (
            <Col style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={state.showParent}
                onChange={(event) => {
                  state.showParent = event.target.checked;
                }}
              >
                Show parent documents
              </Checkbox>
            </Col>
          )}
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              value={state.includeDeleted}
              onChange={(event) => {
                state.includeDeleted = event.target.checked;
              }}
            >
              Include deleted
            </Checkbox>
          </Col>
          {state.entity && (
            <Col style={{ marginLeft: "auto" }}>
              <Button
                onClick={() => {
                  state.entity &&
                    eventBusService.publish(
                      new OpenAddDocumentModal({ entity: state.entity }),
                    );
                }}
                icon={<PlusOutlined />}
              >
                Create
              </Button>
            </Col>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Spin spinning={state.query.isPending}>
          <Space direction="vertical">
            <ErrorsFormatter queries={[state.query]} />
            <Table
              dataSource={state.query.items}
              showHeader={!!state.query.items.length}
              rowKey="id"
              size="small"
              bordered
              pagination={false}
              onRow={(item) => ({
                className: item.deletedAt ? "ant-table-row-color-gray" : "",
              })}
            >
              <Table.Column dataIndex="id" title={"ID"} />
              <Table.Column title="Type" dataIndex="type" />
              <Table.Column title="Title" dataIndex="title" />
              <Table.Column
                title={"Enabled"}
                dataIndex="isActive"
                render={(value: boolean) => <Switch checked={value} disabled />}
              />
              <Table.Column
                title="Updated at"
                render={(_, item: TDocumentFilterItem) => {
                  if (!item.updatedAt) {
                    return "—";
                  }
                  return new Date(item.updatedAt).toLocaleString();
                }}
              />
              <Table.Column
                title="Actions"
                align="right"
                render={(_, item: TDocumentFilterItem) => {
                  return (
                    <Row justify="end" gutter={[12, 12]}>
                      <Col>
                        <Button
                          size="small"
                          icon={<EditOutlined />}
                          title={"Edit"}
                          onClick={() => {
                            eventBusService.publish(
                              new OpenEditDocumentModal({ id: item.id }),
                            );
                          }}
                        />
                      </Col>
                      <Col>
                        <DeleteAction
                          documentId={item.id}
                          disabled={!!item.deletedAt}
                        />
                      </Col>
                    </Row>
                  );
                }}
              />
            </Table>
            <Pagination
              query={state.query}
              onChange={() => {
                state.filter({ preservePageNumber: true });
              }}
            />
          </Space>
        </Spin>
      </Card>
    </Page>
  );
});

export default DocumentsPage;
