import { yupResolver } from "@hookform/resolvers/yup";
import { MenuProps, notification } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { DocumentAPI, LanguageAPI } from "@/api";
import {
  DocumentTranslationAdded,
  DocumentTranslationDeleted,
  DocumentTranslationUpdated,
} from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { EditDocumentFormProps } from "./edit-form";
import {
  EditDocumentFormSchema,
  useEditDocumentFormSchema,
} from "./edit-form.schema";

export function useEditDocumentFormState({ id }: EditDocumentFormProps) {
  const intl = useIntl();

  const [tab, setTab] = useState("en");

  const [usedLanguageCodes, setUsedLanguageCodes] = useState<string[]>([]);

  const query = useMemo(() => {
    const query = new Query(async (_: void) => {
      const document = await DocumentAPI.getById({ id });

      const { title, slug, isActive, requirePlayerAccept } = document;

      form.reset({ title, slug, isActive, requirePlayerAccept });

      setUsedLanguageCodes(document.translations.map((t) => t.lang));

      return document;
    });
    query.submit();
    return query;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTab]);

  const { eventBusService } = useGlobalStore();

  useEffect(() => {
    const handleAdd = (event: DocumentTranslationAdded) =>
      query.submit().then(() => setTab(event.payload.code));
    const handleUpdate = () => query.submit();
    const handleDelete = () => query.submit().then(() => setTab("en"));
    eventBusService.subscribe(DocumentTranslationAdded, handleAdd);
    eventBusService.subscribe(DocumentTranslationUpdated, handleUpdate);
    eventBusService.subscribe(DocumentTranslationDeleted, handleDelete);
    return () => {
      eventBusService.unsubscribe(DocumentTranslationAdded, handleAdd);
      eventBusService.unsubscribe(DocumentTranslationUpdated, handleUpdate);
      eventBusService.unsubscribe(DocumentTranslationDeleted, handleDelete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const translationMap = Object.fromEntries(
    query.data?.translations.map((t) => [t.lang, t]) ?? [],
  );

  const languagesQuery = useMemo(() => {
    const query = new Query(() => LanguageAPI.getAll());
    query.submit({});
    return query;
  }, []);

  const schema = useEditDocumentFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<EditDocumentFormSchema>({ resolver });

  const unusedLanguageCodes = useMemo(
    () =>
      languagesQuery.data?.data
        .map((l) => l.code)
        .filter((code) => !usedLanguageCodes.includes(code)) ?? [],
    [languagesQuery.data?.data, usedLanguageCodes],
  );

  function handleTabRemove(code: string) {
    setUsedLanguageCodes((codes) => codes.filter((c) => c !== code));
  }

  const addLanguageMenu: MenuProps = {
    onClick(item) {
      setUsedLanguageCodes((codes) => [...codes, item.key]);
    },
    items: unusedLanguageCodes.map((code) => ({
      key: code,
      label: code.toUpperCase(),
    })),
  };

  const mutation = useMemo(
    () =>
      new Query(async (values: EditDocumentFormSchema) => {
        await DocumentAPI.update({ id, ...values });

        notification.success({
          message: "",
          description: "The document settings have been successfully saved.",
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = form.handleSubmit(mutation.submit);

  return {
    id,

    intl,

    form,

    tab,
    setTab,

    query,
    languagesQuery,
    mutation,

    translationMap,
    usedLanguageCodes,
    unusedLanguageCodes,

    handleSubmit,
    handleTabRemove,
    addLanguageMenu,
  };
}

export type EditDocumentFormState = ReturnType<typeof useEditDocumentFormState>;
