import { observer } from "mobx-react-lite";

import { CloseEditDocumentModal, OpenEditDocumentModal } from "@/events";
import { useGlobalStore } from "@/stores";
import { EventBasedModal } from "@/ui/_common_/event-based-modal";

import { EditDocumentForm } from "./edit-form";

export const EditDocumentModal = observer(() => {
  const { eventBusService } = useGlobalStore();
  return (
    <EventBasedModal
      openEvent={OpenEditDocumentModal}
      closeEvent={CloseEditDocumentModal}
      title={"Edit Document"}
      onClose={() => eventBusService.publish(new CloseEditDocumentModal({}))}
    >
      {({ id }) => <EditDocumentForm id={id} />}
    </EventBasedModal>
  );
});
