import { observer } from "mobx-react-lite";

import { Entity } from "@/types";

import { useAddDocumentFormState } from "./add-form.state";
import { AddDocumentFormView } from "./add-form.view";

export type AddDocumentFormProps = { entity: Entity };

export const AddDocumentForm = observer<AddDocumentFormProps>((props) => {
  const state = useAddDocumentFormState(props);
  return <AddDocumentFormView state={state} />;
});
