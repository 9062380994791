import { makeAutoObservable } from "mobx";

import { TRole } from "@/api";

import { UserStore } from "..";

export type TPermission =
  | "CreateArticle"
  | "CreateKiosk"
  | "DeleteAgent"
  | "DeleteCashier"
  | "DeleteHall"
  | "DeleteKiosk"
  | "DeletePlayer"
  | "SelectAgent"
  | "SelectCashier"
  | "SelectClient"
  | "SelectHall"
  | "SelectManager"
  | "SelectSubagent"
  | "UndeleteAgent"
  | "UndeleteCashier"
  | "UndeleteHall"
  | "UndeleteKiosk"
  | "UndeletePlayer"
  | "UpdateArticle"
  | "UpdateKiosk"
  | "ViewAgents"
  | "ViewCashiers"
  | "ViewClients"
  | "ViewDailyProfitReport"
  | "ViewDeletedAgents"
  | "ViewDeletedCashiers"
  | "ViewDeletedHalls"
  | "ViewDeletedKiosks"
  | "ViewDeletedPlayers"
  | "ViewGamesReport"
  | "ViewGlobalSearch"
  | "ViewHalls"
  | "ViewHandbook"
  | "ViewHandbookBanners"
  | "ViewHandbookBonuses"
  | "ViewHandbookCurrencies"
  | "ViewHandbookDocuments"
  | "ViewHandbookGames"
  | "ViewHandbookGamesOrder"
  | "ViewHandbookJackpots"
  | "ViewHandbookLanguages"
  | "ViewHandbookRubricator"
  | "ViewHandbookSportPartners"
  | "ViewHandbookWebsites"
  | "ViewKiosks"
  | "ViewPlayers"
  | "ViewPlayersDetails"
  | "ViewReports"
  | "ViewSettlementsReport"
  | "ViewSportSettings"
  | "ViewSweepstakesReport";

const CASHIER_PERMISSIONS: Set<TPermission> = new Set([
  "ViewKiosks",
  "ViewPlayers",
  "ViewReports",
]);
const HALL_ADMIN_PERMISSIONS: Set<TPermission> = new Set([
  ...CASHIER_PERMISSIONS,
  "CreateKiosk",
  "DeleteKiosk",
  "DeletePlayer",
  "SelectCashier",
  "UpdateKiosk",
  "ViewCashiers",
  "ViewPlayersDetails",
]);
const AGENT_ADMIN_PERMISSIONS: Set<TPermission> = new Set([
  ...HALL_ADMIN_PERMISSIONS,
  "DeleteAgent",
  "DeleteCashier",
  "DeleteHall",
  "SelectHall",
  "ViewAgents",
  "ViewGamesReport",
  "ViewHalls",
  "ViewSettlementsReport",
  "ViewSweepstakesReport",
]);
const CLIENT_ADMIN_PERMISSIONS: Set<TPermission> = new Set([
  ...AGENT_ADMIN_PERMISSIONS,
  "SelectAgent",
  "ViewDeletedAgents",
  "ViewDeletedCashiers",
  "ViewDeletedHalls",
  "ViewDeletedKiosks",
  "ViewDeletedPlayers",
  "ViewGlobalSearch",
]);
const CONTENT_MANAGER_PERMISSIONS: Set<TPermission> = new Set([
  "SelectClient",
  "SelectAgent",
  "ViewHandbook",
  "ViewHandbookGames",
  "ViewHandbookGamesOrder",
  "ViewHandbookBanners",
  "ViewHandbookRubricator",
  "ViewHandbookDocuments",
]);
const MANAGER_PERMISSIONS: Set<TPermission> = new Set([
  ...CLIENT_ADMIN_PERMISSIONS,
  "SelectClient",
  "UndeleteAgent",
  "UndeleteCashier",
  "UndeleteHall",
  "UndeleteKiosk",
  "UndeletePlayer",
  "ViewClients",
  "ViewDailyProfitReport",
  "ViewHandbook",
  "ViewHandbookGamesOrder",
  "ViewHandbookBanners",
  "ViewSportSettings",
  "ViewHandbookSportPartners",
  "ViewHandbookDocuments",
]);
const ADMIN_PERMISSIONS: Set<TPermission> = new Set([
  ...MANAGER_PERMISSIONS,
  "CreateArticle",
  "SelectManager",
  "UpdateArticle",
  "ViewHandbookBonuses",
  "ViewHandbookCurrencies",
  "ViewHandbookGames",
  "ViewHandbookJackpots",
  "ViewHandbookLanguages",
  "ViewHandbookWebsites",
  "ViewHandbookRubricator",
]);

AGENT_ADMIN_PERMISSIONS.add("SelectSubagent");

const PERMISSIONS_BY_ROLE: { [key in TRole]: Set<TPermission> } = {
  Admin: ADMIN_PERMISSIONS,
  Manager: MANAGER_PERMISSIONS,
  ClientAdmin: CLIENT_ADMIN_PERMISSIONS,
  AgentAdmin: AGENT_ADMIN_PERMISSIONS,
  HallAdmin: HALL_ADMIN_PERMISSIONS,
  Cashier: CASHIER_PERMISSIONS,
  ContentManager: CONTENT_MANAGER_PERMISSIONS,
};

const DEFAULT_ROUTE_BY_ROLE: { [key in TRole]: string } = {
  Admin: "/clients",
  Manager: "/clients",
  ClientAdmin: "/agents",
  AgentAdmin: "/shops",
  HallAdmin: "/players",
  Cashier: "/players",
  ContentManager: "/handbook/banners",
};

export class PermissionsStore {
  constructor(private userStore: UserStore) {
    makeAutoObservable(this);
  }

  has = (permission: TPermission) => {
    if (!this.userStore.user) {
      return false;
    }
    return PERMISSIONS_BY_ROLE[this.userStore.user.role].has(permission);
  };

  get defaultRoute() {
    if (!this.userStore.user) {
      return "/log-in";
    }
    return DEFAULT_ROUTE_BY_ROLE[this.userStore.user.role];
  }
}
