import { yupResolver } from "@hookform/resolvers/yup";
import { notification } from "antd";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { DocumentAPI } from "@/api";
import { CloseAddDocumentModal, OpenEditDocumentModal } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

import { AddDocumentFormProps } from "./add-form";
import {
  AddDocumentFormSchema,
  useAddDocumentFormSchema,
} from "./add-form.schema";

export function useAddDocumentFormState({ entity }: AddDocumentFormProps) {
  const intl = useIntl();

  const schema = useAddDocumentFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<AddDocumentFormSchema>({
    resolver,
    defaultValues: { type: "contacts", title: "", slug: "" },
  });

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(
    () =>
      new Query(async (values: AddDocumentFormSchema) => {
        const { id } = await DocumentAPI.create({
          ...entity,
          ...values,
        });

        notification.success({
          message: "",
          description: "The document was successfully created.",
        });

        eventBusService.publish(new CloseAddDocumentModal({}));
        eventBusService.publish(new OpenEditDocumentModal({ id }));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = form.handleSubmit(mutation.submit);

  return {
    intl,
    form,
    mutation,
    handleSubmit,
  };
}

export type AddDocumentFormState = ReturnType<typeof useAddDocumentFormState>;
