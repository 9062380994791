import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Row, Tabs } from "antd";
import { observer } from "mobx-react-lite";

import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem, FormSwitch } from "@/ui/_common_/form-helpers";
import { FormSpinner } from "@/ui/_common_/form-spinner";

import { AddTranslationForm } from "./add-translation-form";
import { EditDocumentFormState } from "./edit-form.state";
import { EditTranslationForm } from "./edit-translation-form";

interface Props {
  state: EditDocumentFormState;
}

export const EditDocumentFormView = observer<Props>(
  ({
    state: {
      id,

      form,

      tab,
      setTab,

      query,
      languagesQuery,
      mutation,

      usedLanguageCodes,
      unusedLanguageCodes,
      translationMap,

      handleSubmit,
      handleTabRemove,

      addLanguageMenu,
    },
  }) => {
    if (
      query.isIdle ||
      query.isPending ||
      languagesQuery.isIdle ||
      languagesQuery.isPending
    ) {
      return <FormSpinner />;
    }

    if (query.isRejected || languagesQuery.isRejected) {
      return <ErrorsFormatter queries={[query, languagesQuery]} />;
    }

    return (
      <Form
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ErrorsFormatter queries={[mutation]} />
        <Form.Item label="Type">{query.data.type}</Form.Item>
        <FormItem form={form} path="title" label="Title">
          <FormInput form={form} path="title" placeholder="Enter title" />
        </FormItem>
        <FormItem form={form} path="slug" label="Slug">
          <FormInput form={form} path="slug" placeholder="Enter slug" />
        </FormItem>
        <FormItem form={form} path="isActive" label={"Active"}>
          <FormSwitch form={form} path="isActive" />
        </FormItem>
        <FormItem
          form={form}
          path="requirePlayerAccept"
          label={"Require player accept"}
        >
          <FormSwitch form={form} path="requirePlayerAccept" />
        </FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
            >
              Save
            </Button>
          </Col>
        </Row>

        <Tabs
          className="tabs--container"
          type="editable-card"
          size="small"
          activeKey={tab}
          onChange={setTab}
          onEdit={(key, action) => {
            if (action === "remove") {
              handleTabRemove(key as string);
            }
          }}
          items={usedLanguageCodes.map((code) => {
            return {
              key: code,
              label: code.toUpperCase(),
              closable: false,
              children:
                code in translationMap ? (
                  <EditTranslationForm
                    documentId={id}
                    translation={translationMap[code]}
                  />
                ) : (
                  <AddTranslationForm documentId={id} languageCode={code} />
                ),
            };
          })}
          hideAdd={true}
          tabBarExtraContent={{
            right: !!unusedLanguageCodes.length && (
              <Dropdown menu={addLanguageMenu} trigger={["click"]}>
                <Button size="small">
                  <PlusOutlined />
                </Button>
              </Dropdown>
            ),
          }}
        />
      </Form>
    );
  },
);
