import yup from "@/yup";

export function useAddDocumentFormSchema() {
  return yup.object({
    type: yup
      .mixed<"general" | "contacts" | "wof_howtowork" | "wof_rules">()
      .oneOf(["general", "contacts", "wof_howtowork", "wof_rules"])
      .defined(),
    title: yup.string().min(2).default("").defined(),
    slug: yup.string().min(1).default("").defined(),
  });
}

export type AddDocumentFormSchema = yup.InferType<
  ReturnType<typeof useAddDocumentFormSchema>
>;
