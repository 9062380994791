import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Row } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { DocumentAPI } from "@/api";
import { DocumentTranslationAdded } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem } from "@/ui/_common_/form-helpers";

import {
  AddTranslationFormSchema,
  useAddTranslationFormSchema,
} from "./add-translation-form.schema";

export type AddTranslationFormProps = {
  documentId: number;
  languageCode: string;
};

export const AddTranslationForm = observer<AddTranslationFormProps>((props) => {
  const schema = useAddTranslationFormSchema();
  const resolver = yupResolver(schema);
  const form = useForm<AddTranslationFormSchema>({
    resolver,
    defaultValues: { title: "", body: "" },
  });

  const { eventBusService } = useGlobalStore();

  const mutation = useMemo(() => {
    return new Query(async (values: AddTranslationFormSchema) => {
      await DocumentAPI.createTranslation({
        id: props.documentId,
        lang: props.languageCode,
        ...values,
      });

      eventBusService.publish(
        new DocumentTranslationAdded({ code: props.languageCode }),
      );
    });
  }, [eventBusService, props.documentId, props.languageCode]);

  const handleSubmit = form.handleSubmit((values) => {
    mutation.submit(values);
  });

  return (
    <Form
      size="small"
      component="div"
      labelCol={{ span: 8 }}
      labelAlign="left"
      wrapperCol={{ span: 16 }}
      layout="horizontal"
    >
      <ErrorsFormatter queries={[mutation]} />
      <FormItem form={form} path="title" label="Title">
        <FormInput form={form} path="title" placeholder="Enter title" />
      </FormItem>
      <FormItem form={form} path="body" label="Body">
        <FormInput form={form} path="body" placeholder="Enter body" />
      </FormItem>
      <Row justify="end" gutter={12}>
        <Col>
          <Button
            type="primary"
            loading={mutation.isPending}
            onClick={handleSubmit}
          >
            Create
          </Button>
        </Col>
      </Row>
    </Form>
  );
});
