import { TDocumentDetails, TDocumentFilterItem, TDocumentType } from "..";
import MethodBuilder from "../method-builder";

export class DocumentAPI {
  static filter = new MethodBuilder<
    ({ clientId: number } | { agentId: number } | { hallId: number }) & {
      type?: TDocumentType;
      includeDeleted?: boolean;
      showParent?: boolean;
      paging: {
        limit: number;
        offset: number;
      };
    },
    {
      data: TDocumentFilterItem[];
      total: number;
    }
  >()
    .withName("Document.filter")
    .build();

  static getById = new MethodBuilder<{ id: number }, TDocumentDetails>()
    .withName("Document.getById")
    .build();

  static update = new MethodBuilder<
    {
      id: number;
      title?: string;
      slug?: string;
      isActive?: boolean;
      requirePlayerAccept?: boolean;
    },
    void
  >()
    .withName("Document.update")
    .build();

  static delete = new MethodBuilder<{ id: number }, void>()
    .withName("Document.delete")
    .build();

  static create = new MethodBuilder<
    ({ clientId: number } | { agentId: number } | { hallId: number }) & {
      type: TDocumentType;
      title: string;
      slug: string;
    },
    { id: number }
  >()
    .withName("Document.create")
    .build();

  static createTranslation = new MethodBuilder<
    {
      id: number;
      lang: string;
      title?: string;
      body?: string;
    },
    void
  >()
    .withName("Document.createTranslation")
    .build();

  static updateTranslation = new MethodBuilder<
    {
      id: number;
      lang: string;
      title?: string;
      body?: string;
      version?: string;
    },
    void
  >()
    .withName("Document.updateTranslation")
    .build();

  static deleteTranslation = new MethodBuilder<
    {
      id: number;
      lang: string;
    },
    void
  >()
    .withName("Document.deleteTranslation")
    .build();
}
