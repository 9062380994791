import { observer } from "mobx-react-lite";
import { FC } from "react";
import {
  unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Clients from "@/ui/clients";
import { GlobalSearch } from "@/ui/global-search";
import Kiosks from "@/ui/kiosks";
import * as KnowledgeBase from "@/ui/knowledge-base";
import Agents from "@/ui/pages/agents";
import Cashiers from "@/ui/pages/cashiers";
import Halls from "@/ui/pages/halls";
import Banners from "@/ui/pages/handbook/banners";
import Bonuses from "@/ui/pages/handbook/bonuses";
import Currencies from "@/ui/pages/handbook/currencies";
import Documents from "@/ui/pages/handbook/documents";
import Games from "@/ui/pages/handbook/games";
import { GamesOrder } from "@/ui/pages/handbook/games-order";
import Jackpots from "@/ui/pages/handbook/jackpots";
import Languages from "@/ui/pages/handbook/languages";
import Rubricator from "@/ui/pages/handbook/rubricator";
import SportClubs from "@/ui/pages/handbook/sport-clubs";
import SportPartners from "@/ui/pages/handbook/sport-partners";
import Websites from "@/ui/pages/handbook/websites";
import * as InstantMessages from "@/ui/pages/instant-messages";
import LogIn from "@/ui/pages/log-in";
import BonusReport from "@/ui/pages/reports/bonus-report";
import DailyProfitReport from "@/ui/pages/reports/daily-profit-report";
import FinancialReport from "@/ui/pages/reports/financial-report";
import FortuneWheelReport from "@/ui/pages/reports/fortune-wheel-report";
import GamesReport from "@/ui/pages/reports/games-report";
import HistoryReport from "@/ui/pages/reports/history-report";
import JackpotsReport from "@/ui/pages/reports/jackpots-report";
import PaymentsReport from "@/ui/pages/reports/payments-report";
import SettlementsReport from "@/ui/pages/reports/settlements-report";
import ShiftsReport from "@/ui/pages/reports/shifts-report";
import SportReport from "@/ui/pages/reports/sport-report";
import SummaryReport from "@/ui/pages/reports/summary-report";
import { SweepstakesReport } from "@/ui/pages/reports/sweepstakes-report";
import VouchersReport from "@/ui/pages/reports/vouchers-report";
import Players from "@/ui/players";
import { PlayerDetails } from "@/ui/players/player-details";

import { AuthGuard, PermissionGuard } from "./guards";
import { history } from "./history";

export const Router: FC = observer(() => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route element={<AuthGuard />}>
          <Route element={<PermissionGuard permission="ViewGlobalSearch" />}>
            <Route path="search" element={<GlobalSearch />} />
          </Route>
          <Route element={<PermissionGuard permission="ViewClients" />}>
            <Route path="clients" element={<Clients />} />
          </Route>
          <Route element={<PermissionGuard permission="ViewAgents" />}>
            <Route path="agents" element={<Agents />} />
          </Route>
          <Route element={<PermissionGuard permission="ViewHalls" />}>
            <Route path="shops" element={<Halls />} />
          </Route>
          <Route element={<PermissionGuard permission="ViewCashiers" />}>
            <Route path="cashiers" element={<Cashiers />} />
          </Route>
          <Route element={<PermissionGuard permission="ViewPlayers" />}>
            <Route path="players" element={<Players />} />
          </Route>
          <Route element={<PermissionGuard permission="ViewPlayersDetails" />}>
            <Route path="players/:id" element={<PlayerDetails />} />
          </Route>
          <Route element={<PermissionGuard permission="ViewKiosks" />}>
            <Route path="kiosks" element={<Kiosks />} />
          </Route>
          <Route element={<PermissionGuard permission="ViewReports" />}>
            <Route path="reports">
              <Route path="summary" element={<SummaryReport />} />
              <Route path="history" element={<HistoryReport />} />
              <Route path="financial" element={<FinancialReport />} />
              <Route path="payments" element={<PaymentsReport />} />
              <Route path="bonuses" element={<BonusReport />} />
              <Route path="fortune-wheel" element={<FortuneWheelReport />} />
              <Route path="jackpots" element={<JackpotsReport />} />
              <Route element={<PermissionGuard permission="ViewGamesReport" />}>
                <Route path="games" element={<GamesReport />} />
              </Route>
              <Route
                element={<PermissionGuard permission="ViewDailyProfitReport" />}
              >
                <Route path="daily-profit" element={<DailyProfitReport />} />
              </Route>
              <Route path="vouchers" element={<VouchersReport />} />
              <Route
                element={<PermissionGuard permission="ViewSettlementsReport" />}
              >
                <Route path="settlements" element={<SettlementsReport />} />
              </Route>
              <Route path="shifts" element={<ShiftsReport />} />
              <Route
                element={<PermissionGuard permission="ViewSweepstakesReport" />}
              >
                <Route path="sweepstakes" element={<SweepstakesReport />} />
              </Route>
              {/* <Route element={<PermissionGuard permission="ViewSportReport" />}> */}
              <Route path="sport" element={<SportReport />} />
              {/* </Route> */}
            </Route>
          </Route>
          <Route element={<PermissionGuard permission="ViewHandbook" />}>
            <Route path="handbook">
              <Route
                element={<PermissionGuard permission="ViewHandbookGames" />}
              >
                <Route path="games" element={<Games />} />
              </Route>
              <Route
                element={
                  <PermissionGuard permission="ViewHandbookGamesOrder" />
                }
              >
                <Route path="games-order" element={<GamesOrder />} />
              </Route>
              <Route
                element={
                  <PermissionGuard permission="ViewHandbookCurrencies" />
                }
              >
                <Route path="currencies" element={<Currencies />} />
              </Route>
              <Route
                element={<PermissionGuard permission="ViewHandbookLanguages" />}
              >
                <Route path="languages" element={<Languages />} />
              </Route>
              <Route
                element={<PermissionGuard permission="ViewHandbookWebsites" />}
              >
                <Route path="websites" element={<Websites />} />
              </Route>
              <Route
                element={<PermissionGuard permission="ViewHandbookJackpots" />}
              >
                <Route path="jackpots" element={<Jackpots />} />
              </Route>
              <Route
                element={<PermissionGuard permission="ViewHandbookBonuses" />}
              >
                <Route path="bonuses" element={<Bonuses />} />
              </Route>
              <Route
                element={<PermissionGuard permission="ViewHandbookBanners" />}
              >
                <Route path="banners" element={<Banners />} />
              </Route>
              <Route
                element={
                  <PermissionGuard permission="ViewHandbookRubricator" />
                }
              >
                <Route path="rubricator" element={<Rubricator />} />
              </Route>
              <Route
                element={
                  <PermissionGuard permission="ViewHandbookSportPartners" />
                }
              >
                <Route path="sport-partners" element={<SportPartners />} />
                <Route path="sport-clubs" element={<SportClubs />} />
              </Route>
              <Route
                element={<PermissionGuard permission="ViewHandbookDocuments" />}
              >
                <Route path="documents" element={<Documents />} />
              </Route>
            </Route>
          </Route>
          <Route path="kb">
            <Route index element={<KnowledgeBase.Articles />} />
            <Route path=":slug" element={<KnowledgeBase.Article />} />
          </Route>
          <Route path="instant-messages">
            <Route index element={<InstantMessages.InstantMessages />} />
            <Route
              path=":conversationId"
              element={<InstantMessages.InstantMessagesDetails />}
            />
          </Route>
        </Route>
        <Route path="log-in" element={<LogIn />} />
        <Route path="*" element={<Navigate to="log-in" replace={true} />} />
      </Routes>
    </HistoryRouter>
  );
});
