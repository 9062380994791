import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Form, Row } from "antd";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useForm } from "react-hook-form";

import { DocumentAPI, TDocumentTranslation } from "@/api";
import {
  DocumentTranslationDeleted,
  DocumentTranslationUpdated,
} from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import { FormInput, FormItem, FormTextArea } from "@/ui/_common_/form-helpers";

import {
  EditTranslationFormSchema,
  useEditTranslationFormSchema,
} from "./edit-translation-form.schema";

export type EditTranslationFormProps = {
  documentId: number;
  translation: TDocumentTranslation;
};

export const EditTranslationForm = observer<EditTranslationFormProps>(
  (props) => {
    const schema = useEditTranslationFormSchema();
    const resolver = yupResolver(schema);
    const form = useForm<EditTranslationFormSchema>({
      resolver,
      defaultValues: {
        title: props.translation.title,
        body: props.translation.body,
        version: props.translation.version,
      },
    });

    const { eventBusService } = useGlobalStore();

    const saveMutation = useMemo(() => {
      return new Query(async (values: EditTranslationFormSchema) => {
        await DocumentAPI.updateTranslation({
          id: props.documentId,
          lang: props.translation.lang,
          ...values,
        });

        eventBusService.publish(new DocumentTranslationUpdated({}));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = form.handleSubmit((values) => {
      saveMutation.submit(values);
    });

    const deleteMutation = useMemo(() => {
      return new Query(async () => {
        await DocumentAPI.deleteTranslation({
          id: props.documentId,
          lang: props.translation.lang,
        });

        eventBusService.publish(new DocumentTranslationDeleted({}));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Form
        component="div"
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        layout="horizontal"
      >
        <ErrorsFormatter queries={[saveMutation, deleteMutation]} />
        <FormItem form={form} path="title" label="Title">
          <FormInput form={form} path="title" placeholder="Enter title" />
        </FormItem>
        <FormItem form={form} path="body" label="Body">
          <FormTextArea form={form} path="body" placeholder="Enter body" />
        </FormItem>
        <FormItem form={form} path="version" label="Version">
          <FormInput form={form} path="version" placeholder="Enter version" />
        </FormItem>
        <Form.Item label="Updated at">
          {props.translation.updatedAt
            ? new Date(props.translation.updatedAt).toLocaleString()
            : "—"}
        </Form.Item>
        <Form.Item label="Updated by">
          {props.translation.updateUserLogin
            ? props.translation.updateUserLogin
            : "—"}
        </Form.Item>
        <Row justify="end" gutter={12}>
          {props.translation.lang !== "en" && (
            <Col>
              <Button
                type="primary"
                color="red"
                loading={deleteMutation.isPending}
                onClick={deleteMutation.submit}
              >
                Delete
              </Button>
            </Col>
          )}
          <Col>
            <Button
              type="primary"
              loading={saveMutation.isPending}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  },
);
