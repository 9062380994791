import yup from "@/yup";

export function useAddTranslationFormSchema() {
  return yup.object({
    title: yup.string().default("").defined(),
    body: yup.string().default("").defined(),
  });
}

export type AddTranslationFormSchema = yup.InferType<
  ReturnType<typeof useAddTranslationFormSchema>
>;
