import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";

import { DocumentAPI } from "@/api";
import { DocumentDeleted } from "@/events";
import Query from "@/models/query";
import { useGlobalStore } from "@/stores";

type Props = {
  documentId: number;
  disabled?: boolean;
};

export const DeleteAction = observer<Props>((props) => {
  const mutation = useMemo(() => new Query(DocumentAPI.delete), []);

  const { eventBusService } = useGlobalStore();

  const handleClick = useCallback(async () => {
    await mutation.submit({ id: props.documentId });

    eventBusService.publish(new DocumentDeleted({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      size="small"
      icon={<DeleteOutlined />}
      loading={mutation.isPending}
      onClick={handleClick}
      disabled={props.disabled}
    />
  );
});
